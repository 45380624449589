<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-white pt-24">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <div class="max-w-lg mx-auto md:max-w-none md:grid md:grid-cols-2 md:gap-8">
        <div class="mt-12 sm:mt-16 md:mt-0 mb-10">
          <img src="/logo2.png" class="w-40 h-20 mb-4">
          <h2 class="text-2xl font-extrabold text-gray-900 sm:text-3xl">
            IC&D group
          </h2>
          <div class="mt-9 space-y-6">

            <div class="flex">
              <div class="flex-shrink-0">
                <MapIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
              </div>
              <div class="ml-3 text-base text-gray-500">
                <p>
                  Bore Stankovića 6b, Makiš, Beograd, Srbija
                </p>
              </div>
            </div>

            <div class="flex">
              <div class="flex-shrink-0">
                <PhoneIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
              </div>
              <div class="ml-3 text-base text-gray-500">
                <a href="tel:+381 66 8027 033" class="hover:underline">
                  +381 66 8027 033 (RS)
                </a>
              </div>
            </div>

            <div class="flex">
              <div class="flex-shrink-0">
                <PhoneIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
              </div>
              <div class="ml-3 text-base text-gray-500">
                <a href="tel:+381 66 8027 033" class="hover:underline">
                  +381 64 2384 538 (RS)
                </a>
              </div>
            </div>

            <div class="flex">
              <div class="flex-shrink-0">
                <PhoneIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
              </div>
              <div class="ml-3 text-base text-gray-500">
                <a href="tel:+381 66 8027 033" class="hover:underline">
                  +387 65 305 475 (BiH)
                </a>
              </div>
            </div>

            <div class="flex">
              <div class="flex-shrink-0">
                <PhoneIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
              </div>
              <div class="ml-3 text-base text-gray-500">
                <a href="tel:+381 66 8027 033" class="hover:underline">
                  +381 64 2493032 (Sva pitanja)
                </a>
              </div>
            </div>

            <div class="flex">
              <div class="flex-shrink-0">
                <CursorClickIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
              </div>
              <div class="ml-3 text-base text-gray-500">
                <a href="www.hob.rs" target="_blank" class="hover:underline">
                  www.hob.rs
                </a>
              </div>
            </div>

            <div class="flex">
              <div class="flex-shrink-0">
                <MailIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
              </div>
              <div class="ml-3 text-base text-gray-500">
                <a href="mailto:support@hob.rs" class="hover:underline">
                  support@hob.rs
                </a>
              </div>
            </div>

<!--            <div class="flex">-->
<!--              <div class="flex-shrink-0">-->
<!--                <MailIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />-->
<!--              </div>-->
<!--              <div class="ml-3 text-base text-gray-500">-->
<!--                <a href="mailto:training@pta.edu.rs" class="hover:underline">-->
<!--                  training@pta.edu.rs-->
<!--                </a>-->
<!--              </div>-->
<!--            </div>-->


          </div>
        </div>

        <div class="w-full" style="aspect-ratio: 1/1;" v-html='`<div class="mapouter"><div class="gmap_canvas">
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2833.1353655361254!2d20.34937511578853!3d44.757653979099!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a6e87d0d370c9%3A0xe4411c5cdc1dfa56!2z0JHQvtGA0LUg0KHRgtCw0L3QutC-0LLQuNGb0LAg0JzQsNC60LjRiCA2LCDQkdC10L7Qs9GA0LDQtA!5e0!3m2!1ssr!2srs!4v1653548299663!5m2!1ssr!2srs" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
<a href="https://fmovies-online.net"></a><br><style>.mapouter{text-align:right; width: 100%; height: 100%;}</style><a href="https://www.embedgooglemap.net"></a><style>.gmap_canvas {overflow:hidden;background:none!important; height: 100%; width: 100%;}</style></div></div>`'></div>
      </div>
    </div>
  </div>
</template>

<script>
import { MailIcon, PhoneIcon, MapIcon, CursorClickIcon } from '@heroicons/vue/outline'
import {  } from '@heroicons/vue/solid'

export default {
  components: {
    MailIcon,
    PhoneIcon,
    MapIcon,
    CursorClickIcon
  },
}
</script>